<script>
    import axios from 'axios';

    function suggestDescription() {
        let validDescription = validateDescription();
        if (validDescription) {
            document.querySelector('#ai-request').classList.remove('is-invalid');
            let route = app.baseUrl + '/suggestions/generate';
            console.log('working');
            let data = {
                text: document.querySelector('#ai-request').value,
            };
            axios
                .post(route, data)
                .then((response) => {
                    if (response.data.message) {
                        document.querySelector('#ai-request').value = response.data.message;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 422 || error.response.status === 500) {
                            launchToast('danger', trans('Error'), error.response.data.message);
                        } else if (error.response.status === 403) {
                            launchToast('danger', trans('Error'), 'Something went wrong, please try again');
                        }
                    }
                });
        }
    }

    function validateDescription() {
        let description = document.querySelector('#ai-request').value;
        if (description.length < 5) {
            document.querySelector('#ai-request').classList.add('is-invalid');
            return false;
        }
        return true;
    }

    function clearSuggestion() {
        document.querySelector('#ai-request').value = '';
    }

    function saveSuggestion() {
        let description = document.querySelector('#ai-request').value;
        let validDescription = validateDescription();
        if (validDescription) {
            document.querySelector('#ai-request').classList.remove('is-invalid');
            document.querySelector('#bio').value = description;
            document.querySelector('#ai-request').classList.remove('is-invalid');
            document.querySelector('.saveBtn').setAttribute('data-dismiss', 'modal');
        }
    }
</script>

<div class="modal fade" tabindex="-1" role="dialog" id="suggest-description-dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Suggest a description</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Use AI to generate your description.</p>
                <div class="input-group">
                    <textarea
                        id="ai-request"
                        rows="6"
                        type="text"
                        class="form-control"
                        name="text"
                        required
                        placeholder="Add a few words about what your suggestion should be about"
                    ></textarea>
                    <span class="invalid-feedback" role="alert">
                        <strong>Description must be at least 5 characters.</strong>
                    </span>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <div>
                    <button type="button" class="btn btn-white mb-0" on:click="{clearSuggestion}">Clear</button>
                </div>
                <div>
                    <button
                        type="button"
                        class="btn btn-secondary suggest-description mb-0"
                        on:click="{suggestDescription}">Suggest</button
                    >
                    <button type="button" class="btn btn-primary saveBtn mb-0" on:click="{saveSuggestion}">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
